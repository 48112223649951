import React, { useState } from "react";
import classNames from "classnames";
import style from "./Accordion.module.scss";

const Accordion = (props) => {
  const [open, setOpen] = useState(false);

  return (
    <div
      className={classNames(style.Accordion, {
        [style.Accordion__open]: open,
      })}>
      <div className={style.title} onClick={() => setOpen(!open)}>
        {props.title}
      </div>
      <div className={style.content}>{props.children}</div>
    </div>
  );
};

export default Accordion;
