import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import style from "./CurrentWeather.module.scss";
import { getWeatherIcon } from "../../utils";

const CurrentWeather = () => {
  const currentData = useSelector((state) => state.current);

  const [temperature, setTemperature] = useState(null);
  const [feelsLike, setFeelsLike] = useState(null);
  const [pressure, setPressure] = useState(null);
  const [humidity, setHumidity] = useState(null);
  const [weatherType, setWeatherType] = useState(null);

  useEffect(() => {
    if (currentData) {
      setTemperature(currentData.temperature);
      setFeelsLike(currentData.feelsLike);
      setPressure(currentData.pressure);
      setHumidity(currentData.humidity);
      setWeatherType(currentData.weatherType);
    }
  }, [currentData]);

  return (
    <>
      {currentData && (
        <>
          <h4>Current Weather</h4>
          <div className={style.CurrentWeather}>
            <div className={style.leftContainer}>
              <div className={style.icon}>{getWeatherIcon(weatherType)}</div>
              <div className={style.temperature}>{temperature}° C</div>
            </div>
            <div className={style.rightContainer}>
              <div className={style.otherValues}>Feels Like {feelsLike}° C</div>
              <div className={style.otherValues}>Pressure: {pressure} hPa</div>
              <div className={style.otherValues}>Humidity {humidity} %</div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CurrentWeather;
