import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getSavedCities } from "../../utils";
import style from "./SuggestedCities.module.scss";

const SuggestedCities = (props) => {
  const [savedCities, setSavedCities] = useState(null);
  const forecastData = useSelector((state) => state.forecast);

  // Load saved cities on first render
  useEffect(() => {
    setSavedCities(getSavedCities());
  }, []);

  return (
    <>
      {!forecastData && savedCities && savedCities.length > 0 && (
        <div className={style.savedCitiesList}>
          <h2>Pick former city</h2>
          {savedCities.map((savedCity, idx) => (
            <div
              onClick={() => props.onSelected(savedCity)}
              key={`city-${savedCity}-${idx}`}
              className={style.savedCity}
            >
              {savedCity}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default SuggestedCities;
