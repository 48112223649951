import React, { useState } from "react";
import style from "./App.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  callForecastDataAsync,
  clearData,
  callCurrentDataAsync,
} from "./redux/actions";
import {
  Input,
  Button,
  SuggestedCities,
  Forecast,
  CurrentWeather,
} from "./components";
import classNames from "classnames";
import { writeCityToStorage } from "./utils";

const App = () => {
  const [city, setCity] = useState("");
  const dispatch = useDispatch();
  const error = useSelector((state) => state.error);

  const handleEnter = (varCity) => {
    if (!varCity) writeCityToStorage(city);
    dispatch(clearData());
    dispatch(callForecastDataAsync(varCity ? varCity : city));
    dispatch(callCurrentDataAsync(varCity ? varCity : city));
  };

  return (
    <div className={style.App}>
      <h1>Icebear Labs Weather</h1>
      <h2>Search City</h2>
      <div className={style.line}>
        <Input
          label="City"
          value={city}
          onEnter={() => handleEnter()}
          onChange={(e) => setCity(e.target.value)}
        />
        <Button onClick={() => handleEnter()} label="Search" />
      </div>
      {error && (
        <div className={classNames(style.line, style.error)}>{error}</div>
      )}
      <SuggestedCities
        onSelected={(selectedCity) => {
          setCity(selectedCity);
          handleEnter(selectedCity);
        }}
      />
      <CurrentWeather />
      <Forecast />
    </div>
  );
};

export default App;
