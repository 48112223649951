import React from "react";
import { useSelector } from "react-redux";
import { getDateString } from "../../utils";
import style from "./Forecast.module.scss";
import { Accordion, Card, WeatherInfo } from "../";

const Forecast = () => {
  const forecastData = useSelector((state) => state.forecast);

  return (
    <>
      {forecastData && (
        <>
          <h4>Forecast</h4>
          <div className={style.list}>
            {Object.keys(forecastData).map((dayKey, accIdx) => (
              <Accordion
                title={getDateString(dayKey)}
                key={`accordion-${accIdx}`}
              >
                {Object.keys(forecastData[dayKey]).map((hourKey, cardIdx) => (
                  <Card key={`card-${cardIdx}`}>
                    <WeatherInfo
                      data={forecastData[dayKey][hourKey]}
                      hour={hourKey}
                    />
                  </Card>
                ))}
              </Accordion>
            ))}
          </div>
        </>
      )}
    </>
  );
};

export default Forecast;
