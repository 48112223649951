import moment from "moment";
import React from "react";
import { WEATHER_TYPES } from "./types";
import { ReactComponent as ClearIcon } from "./assets/img/svg/sun-solid.svg";
import { ReactComponent as CloudIcon } from "./assets/img/svg/cloud-sun-solid.svg";
import { ReactComponent as DrizzleIcon } from "./assets/img/svg/cloud-sun-rain-solid.svg";
import { ReactComponent as RainIcon } from "./assets/img/svg/cloud-showers-heavy-solid.svg";
import { ReactComponent as SnowIcon } from "./assets/img/svg/snowflake-regular.svg";
import { ReactComponent as ThunderstormIcon } from "./assets/img/svg/poo-storm-solid.svg";

const KEY_SAFED_CITIES = "ibl_weather_saved_cities";

/**
 * Save last three cities to storage
 * @param city
 */
export const writeCityToStorage = (city) => {
  const lowerCaseCity = city.toLowerCase();
  const savedCities = JSON.parse(window.localStorage.getItem(KEY_SAFED_CITIES));

  if (savedCities) {
    let newCities = savedCities;
    if (savedCities.indexOf(lowerCaseCity) === -1) {
      if (savedCities.length > 2) {
        newCities = savedCities.slice(1);
      }
      newCities.push(lowerCaseCity);
      window.localStorage.setItem(KEY_SAFED_CITIES, JSON.stringify(newCities));
    }
  } else {
    window.localStorage.setItem(
      KEY_SAFED_CITIES,
      JSON.stringify([lowerCaseCity])
    );
  }
};

export const getSavedCities = () => {
  return JSON.parse(window.localStorage.getItem(KEY_SAFED_CITIES));
};

export const getDateString = (dateKey) => {
  const momentDate = moment(dateKey, "MM-DD-YYYY");

  if (momentDate.dayOfYear() === moment().dayOfYear()) {
    return "Today";
  } else if (momentDate.dayOfYear() === moment().add(1, "day").dayOfYear()) {
    return "Tomorrow";
  }

  return momentDate.format("dddd, DD. MMMM");
};

export const getWeatherIcon = (weatherType) => {
  switch (weatherType) {
    case WEATHER_TYPES.CLEAR:
      return <ClearIcon />;
    case WEATHER_TYPES.CLOUDS:
      return <CloudIcon />;
    case WEATHER_TYPES.DRIZZLE:
      return <DrizzleIcon />;
    case WEATHER_TYPES.RAIN:
      return <RainIcon />;
    case WEATHER_TYPES.SNOW:
      return <SnowIcon />;
    case WEATHER_TYPES.THUNDERSTORM:
      return <ThunderstormIcon />;
    default:
      return <ClearIcon />;
  }
};
