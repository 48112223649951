import React from "react";
import style from "./Card.module.scss";

const Card = (props) => {
  return (
    <div className={style.Card}>
      <div className={style.visibleCard}>{props.children}</div>
    </div>
  );
};

export default Card;
