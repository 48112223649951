import api from "../../api";
import ACTIONS from "../actionTypes";
import moment from "moment";

export const setForecastData = (data) => {
  return {
    type: ACTIONS.SET_FORECAST_DATA,
    payload: {
      data,
    },
  };
};

export const setCurrentData = (data) => {
  return {
    type: ACTIONS.SET_CURRENT_DATA,
    payload: {
      data,
    },
  };
};

export const setError = (message) => {
  return {
    type: ACTIONS.SET_ERROR,
    payload: {
      message,
    },
  };
};

export const clearError = () => {
  return {
    type: ACTIONS.CLEAR_ERROR,
  };
};

export const clearData = () => {
  return {
    type: ACTIONS.CLEAR_DATA,
  };
};

export const callForecastDataAsync = (city) => async (dispatch) => {
  return api.get5Day3Hour(city).then((data) => {
    // In case they fix this
    const code = data.cod ?? data.code;

    if (code === "200") {
      dispatch(clearError());
      dispatch(setForecastData(convertWeatherListData(data)));
    }

    if (code === "404") {
      dispatch(
        setError(`Error: ${data.message ? data.message : "Unknown cause :("}`)
      );
    }
  });
};

export const callCurrentDataAsync = (city) => async (dispatch) => {
  return api.getCurrentWeather(city).then((data) => {
    // In case they fix this
    const code = data.cod ?? data.code;

    if (code === 200) {
      dispatch(clearError());
      dispatch(setCurrentData(convertCurrentWeatherData(data)));
    }

    if (code === "404") {
      dispatch(
        setError(`Error: ${data.message ? data.message : "Unknown cause :("}`)
      );
    }
  });
};

const convertWeatherListData = (data) => {
  return data.list.reduce((result, item) => {
    const date = moment(item.dt, "X");
    const dateString = date.format("MM-DD-YYYY");
    result[dateString] = {
      ...result[dateString],
      [date.hour()]: {
        temperature: Math.round(item.main.temp),
        feelsLike: Math.round(item.main.feels_like),
        pressure: item.main.pressure,
        humidity: item.main.humidity,
        weatherType: item.weather[0].main.toLowerCase(),
      },
    };
    return result;
  }, {});
};

const convertCurrentWeatherData = (data) => {
  return {
    temperature: Math.round(data.main.temp),
    feelsLike: Math.round(data.main.feels_like),
    pressure: data.main.pressure,
    humidity: data.main.humidity,
    weatherType: data.weather[0].main.toLowerCase(),
  };
};
