import React from "react";
import style from "./Button.module.scss";
import classNames from "classnames";

const Button = (props) => {
  return (
    <button className={classNames(style.Button)} onClick={props.onClick}>
      {props.label}
    </button>
  );
};

export default Button;
