import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import reducer from "./reducers";

const composeEnhancers = composeWithDevTools({});

let store = null;

if (process.env.NODE_ENV === "production") {
  store = createStore(reducer, applyMiddleware(thunk));
} else {
  store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));
}

export default store;
