import produce from "immer";
import ACTIONS from "../actionTypes";

const initialState = {
  current: null,
  forecast: null,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_FORECAST_DATA:
      return produce(state, (draftState) => {
        draftState.forecast = action.payload.data;
      });
    case ACTIONS.SET_CURRENT_DATA:
      return produce(state, (draftState) => {
        draftState.current = action.payload.data;
      });
    case ACTIONS.SET_ERROR:
      return produce(state, (draftState) => {
        draftState.current = null;
        draftState.forecast = null;
        draftState.error = action.payload.message;
      });
    case ACTIONS.CLEAR_ERROR:
      return produce(state, (draftState) => {
        draftState.error = null;
      });
    case ACTIONS.CLEAR_DATA:
      return produce(state, (draftState) => {
        draftState.forecast = null;
        draftState.current = null;
      });
    default:
      return state;
  }
};

export default reducer;
