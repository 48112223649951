import React from "react";
import style from "./WeatherInfo.module.scss";
import { getWeatherIcon } from "../../utils";

const WeatherInfo = (props) => {
  const {
    hour,
    data: { temperature, weatherType, feelsLike, pressure, humidity },
  } = props;

  return (
    <div className={style.WeatherInfo}>
      <div className={style.time}>{hour}:00</div>
      <div className={style.icon}>{getWeatherIcon(weatherType)}</div>
      <div className={style.temperature}>{temperature}° C</div>
      <div className={style.otherValues}>Feels Like {feelsLike}° C</div>
      <div className={style.otherValues}>Pressure: {pressure} hPa</div>
      <div className={style.otherValues}>Humidity {humidity} %</div>
    </div>
  );
};

export default WeatherInfo;
