import React, { useEffect } from "react";
import style from "./Input.module.scss";
import classNames from "classnames";

const Input = (props) => {
  useEffect(() => {
    const listener = (e) => {
      if (e.key === "Enter") {
        props.onEnter();
      }
    };

    document.addEventListener("keypress", listener);

    return () => {
      document.removeEventListener("keypress", listener);
    };
  });

  return (
    <div className={style.Input}>
      <input
        className={classNames(style.input)}
        value={props.value}
        placeholder={props.label}
        onChange={props.onChange}
      ></input>
    </div>
  );
};

export default Input;
