const baseUrl = "https://api.openweathermap.org/data/2.5";
const baseAppendage = `&appid=${process.env.REACT_APP_OPENWEATHER_API_KEY}&units=metric`;

const api = {
  get5Day3Hour: (city) => {
    return fetch(`${baseUrl}/forecast?q=${city}${baseAppendage}`).then((res) =>
      res.json()
    );
  },
  getCurrentWeather: (city) => {
    return fetch(`${baseUrl}/weather?q=${city}${baseAppendage}`).then((res) =>
      res.json()
    );
  },
};

export default api;
